import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: "Main",
        component: () => import("@/views/About.vue")
      },
      {
        path: "/office",
        name: "Office",
        meta: { title: "ホーム画面" },
        component: () => import("@/views/Office.vue")
      },
      {
        path: "/list/:name",
        name: "List",
        component: () => import("@/views/TableView/List.vue")
      },
      {
        path: "/details/shain",
        name: "ShainDetails",
        component: () => import("@/views/TableView/ShainDetails.vue")
      },
      {
        path: "/details/shohin",
        name: "ShohinDetails",
        component: () => import("@/views/TableView/ShohinDetails.vue")
      },
      {
        path: "/details/tokuiGroup",
        name: "TokuiGroupDetails",
        component: () => import("@/views/TableView/TokuiGroupDetails.vue")
      },
      {
        path: "/details/tanni",
        name: "TanniDetails",
        component: () => import("@/views/TableView/TanniDetails.vue")
      },
      {
        path: "/jyutyuFormat",
        name: "JyutyuFormat",
        component: () => import("@/views/JyutyuFormat.vue")
      },
      {
        path: "/jyutyuInput",
        name: "JyutyuInput",
        component: () => import("@/views/JyutyuInput.vue")
      },
      {
        path: "/details/jyutyuInput",
        name: "JyutyuInputDetails",
        component: () => import("@/views/TableView/JyutyuInputDetails.vue")
      },
      {
        path: "/details/tanto",
        name: "TantoDetails",
        component: () => import("@/views/TableView/TantoDetails.vue")
      },
      {
        path: "/exportjyutyu",
        name: "ExportJyutyu",
        component: () => import("@/views/ExportJyutyu.vue")
      },
      {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue")
      },
      {
        path: "/debug",
        name: "Debug",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Debug.vue")
      },
      {
        path: "/exceljyutyu",
        name: "ExcelJyutyu",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ExcelJyutyu.vue")
      },
      {
        path: "/excelpicking",
        name: "ExcelPicking",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ExcelPicking.vue")
      },
      {
        path: "/excelnouhin",
        name: "ExcelNouhin",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ExcelNouhin.vue")
      },
      {
        path: "/excelhattyu",
        name: "ExcelHattyu",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ExcelHattyu.vue")
      },
      {
        path: "/jyutyuoutput",
        name: "JyutyuOutput",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/JyutyuOutput.vue")
      },
      {
        path: "/order-batch-entry",
        name: "OrderBatchEntry",
        component: () => import("@/views/batchEntry/OrderBatchEntry.vue")
      },
      {
        path: "/received-order-batch-entry",
        name: "ReceivedOrderBatchEntry",
        component: () =>
          import("@/views/batchEntry/ReceivedOrderBatchEntry.vue")
      },
      {
        path: "/authinfo",
        name: "AuthInfoList",
        meta: { title: "ログインアカウント" },
        component: () => import("@/views/AuthInfoList.vue")
      }
    ]
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      public: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(beforeEach);

export default router;
