export default [
  {
    to: "/office",
    icon: "mdi-briefcase-clock",
    text: "ホーム",
    authority: [0, 1, 2, 3, 5, 9]
  },
  {
    to: "/jyutyuFormat",
    text: "受発注フォーマット",
    icon: "mdi-monitor",
    authority: [2, 3, 4, 5, 9]
  },
  {
    to: "/list/jyutyuInput",
    text: "受発注入力",
    icon: "mdi-monitor-cellphone-star",
    authority: [1, 2, 3, 4, 5, 9]
  },
  {
    open: false,
    text: "一括入力",
    icon: "mdi-monitor-cellphone-star",
    authority: [1, 2, 3, 4, 5, 9],
    children: [
      {
        to: "/order-batch-entry",
        text: "一括発注入力",
        icon: "mdi-monitor-cellphone-star",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        to: "/received-order-batch-entry",
        text: "一括実数入力",
        icon: "mdi-monitor-cellphone-star",
        authority: [2, 3, 4, 5, 9]
      }
    ]
  },
  {
    to: "/jyutyuoutput",
    icon: "mdi-cloud-download-outline",
    text: "受発注データ出力",
    authority: [2, 3, 5, 9]
  },
  {
    open: false,
    text: "Excel出力",
    icon: "mdi-file-download",
    authority: [1, 2, 3, 4, 5, 9],
    children: [
      {
        text: "受注一覧表",
        icon: "mdi-file-excel",
        to: "/exceljyutyu",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        text: "ピッキングリスト",
        icon: "mdi-file-excel",
        to: "/excelpicking",
        authority: [2, 3, 4, 5, 9]
      },
      {
        text: "納品書",
        icon: "mdi-file-excel",
        to: "/excelnouhin",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        text: "発注数リスト",
        icon: "mdi-file-excel",
        to: "/excelhattyu",
        authority: [2, 3, 4, 5, 9]
      }
    ]
  },
  {
    to: "/list/shain",
    icon: "mdi-account-multiple",
    text: "ユーザーマスタ",
    authority: [0, 1, 2, 3, 5, 9]
  },
  {
    to: "/list/shohin",
    icon: "mdi-tools",
    text: "商品マスタ",
    authority: [2, 3, 5, 9]
  },
  {
    to: "/list/tanni",
    icon: "mdi-tools",
    text: "単位マスタ",
    authority: [2, 3, 5, 9]
  },
  {
    to: "/list/tanto",
    icon: "mdi-tools",
    text: "担当マスタ",
    authority: [2, 3, 5, 9]
  },
  {
    to: "/list/tokuiGroup",
    icon: "mdi-tools",
    text: "得意先グループマスタ",
    authority: [2, 3, 5, 9]
  },
  {
    open: false,
    text: "システム管理",
    icon: "mdi-account-cowboy-hat",
    authority: [9],
    children: [
      {
        to: "/list/code-group",
        icon: "mdi-database",
        text: "コードグループ",
        authority: [9]
      },
      {
        text: "デバッグ",
        icon: "mdi-vuejs",
        to: "/debug",
        authority: [9]
      }
    ]
  },
  {
    text: "ログアウト",
    icon: "mdi-logout",
    to: "/login",
    authority: [0, 1, 2, 3, 4, 5, 9]
  }
];
