export const loading = {
  namespaced: true,
  state: {
    isLoading: false
  },
  mutations: {
    set(state: any, isLoading: boolean) {
      console.log(state, isLoading);
      state.isLoading = isLoading;
    }
  },
  actions: {
    async loadData({ commit }: any, promise: Promise<any> | Promise<any>[]) {
      try {
        console.log("loading start");
        commit("increment", true);
        if (Array.isArray(promise)) {
          console.log(await Promise.all([...promise]));
        } else {
          console.log(await Promise.all([promise]));
        }
        console.log("loading end");
      } catch (e) {
        console.error(e);
      } finally {
        commit("increment", false);
      }
    }
  }
};
